import React from "react"
import Layout from "../../components/Layout"
import useSuccessfulPurchaseData from "../../static_queries/useSuccessfulPurchaseData"
import withLocation from "../../components/withLocation"

function SuccessfulPurchase({search}) {
  const { frontmatter, html } = useSuccessfulPurchaseData()
  const { workbook} = search

  return (
    <Layout b2c={true}  showCta={false} title={frontmatter.title}>

      <div className="html-page" dangerouslySetInnerHTML={{__html: html}} />

      <p className="hero-cta hero-copy hero-text">
        <a className="button button-primary button-shadow" href={`https://${workbook}.livingwisely.org`}>Click Here to Get Started</a>
      </p>
    </Layout>
  )
}

export default withLocation(SuccessfulPurchase)

