import { graphql, useStaticQuery } from "gatsby"

export default function useSuccessfulPurchaseData() {
  const data = useStaticQuery(graphql`
     query getSuccessfulPurchaseData {
      markdownRemark(frontmatter: {url: {eq: "successful-purchase"}}) {
    id
    html
     frontmatter {
      title
    }
  }
  }

  `)
  return data.markdownRemark
}


